import { alpha, Box, Container } from '@mui/system';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Alert,
  AlertTitle,
  Backdrop,
  Tooltip,
} from '@mui/material';

import { parseId } from 'src/utils/parsing';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useContext, useEffect, useMemo, useState, useRef, useCallback } from 'react';

import useSetState from 'src/hooks/useSetState';

import { ColDef } from 'ag-grid-community';

import APICache from 'src/services/APICache';

import {
  GroupPermissionsApiResponse,
  GroupRestrictionLevel,
} from 'src/types/models';

import { enqueueSnackbar } from 'notistack';

import { isEmpty } from 'lodash';

import { AgGridReact } from 'ag-grid-react';

import SelectModel from '../shared/SelectModel';

import Breadcrumbs, {
  IBreadcrumb,
} from '../ScenarioManager/EditAssumptionsDetail/NavBreadCrumbs';

import UtilClasses from '../../assets/stylesheets/UtilClasses.module.scss';

import Table from '../shared/Table';

import InformationIcon from '../shared/InfoIcon';

import PopupDialog from '../shared/PopupDialog';

import * as API from '../../services/API';
import APICacheContext from '../shared/APICacheContext';

import CenteredSpinner from '../shared/CenteredSpinner';

import SelectClient from './SelectClient';

import OverflowTip from './../shared/OverflowTooltip';

const DEFAULT_FILTER = 'client';

interface IEditGroupPermissions {
  confirmationFlag: boolean;
  isDeleting: boolean;
  error: undefined;
  deleteModalFlag: boolean;
  loadingData: boolean;
  savingData: boolean;
  checkedHeaderList: Array<String>[];
  newApiResponse: GroupPermissionsApiResponse;
}

const initialState: IEditGroupPermissions = {
  confirmationFlag: false,
  isDeleting: false,
  error: undefined,
  deleteModalFlag: false,
  loadingData: false,
  savingData: false,
  checkedHeaderList: [],
  newApiResponse: {} as GroupPermissionsApiResponse,
};

const EditGroupPermissions = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [state, setState] = useSetState<IEditGroupPermissions>(initialState);

  const userPermissionsRows = state.newApiResponse?.GroupPermission || [];

  const gridRef = useRef<AgGridReact<(typeof userPermissionsRows)[0]>>(null);

  // const [enableSave, setEnableSave] = useState(false);

  const params = () => {
    const clientId = parseId(searchParams.get('client'));
    const modelId = parseId(searchParams.get('model'));
    const userId = parseId(searchParams.get('user'));
    const filter = searchParams.get('filter') || DEFAULT_FILTER;
    const membershipId = parseId(searchParams.get('membershipId'));

    return { clientId, modelId, filter, userId, membershipId };
  };

  const { clientId, modelId, userId, filter, membershipId } = params();

  const getData = async (): Promise<void> => {
    try {
      setState({ loadingData: true, error: undefined });
      const groupPermissionsData = await API.load(
        `/models/${params().modelId}/users/${userId}/group_memberships`
      );
      setState({ newApiResponse: groupPermissionsData, loadingData: false });
    } catch (error: any) {
      setState({ loadingData: false, error });
      enqueueSnackbar(error.body.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getSearchParams = (obj: Record<string, any>): string => {
    const params = new URLSearchParams(obj);
    return params.toString();
  };

  const resetAllGroupsSelections = () => {
    state.newApiResponse.GroupPermission.forEach((group: any) => {
      group.HasGroupAccessToAllModelInstances = false;
      return group;
    });
  };

  const resetAllModelInstanceSelections = () => {
    state.newApiResponse.GroupPermission.forEach((group: any) => {
      group.ModelInstances.map((obj: any) => {
        if (obj.HasGroupAccess !== null) {
          obj.HasGroupAccess = obj.HasGroupAccess === null ? null : false;
        }
        return obj;
      });
    });
  };

  const handleRestrictionLevelChange = () => {
    if (
      state.newApiResponse.GroupRestrictionLevel ===
      GroupRestrictionLevel.ModelLevelAccess
    ) {
      resetAllGroupsSelections();
    } else {
      resetAllModelInstanceSelections();
    }
    setState({
      newApiResponse: {
        ...state.newApiResponse,
        GroupRestrictionLevel:
          state.newApiResponse.GroupRestrictionLevel ===
          GroupRestrictionLevel.ModelLevelAccess
            ? GroupRestrictionLevel.ModelInstanceLevelAccess
            : GroupRestrictionLevel.ModelLevelAccess,
      },
      confirmationFlag: false,
    });
    // setEnableSave(true)
  };

  const breadcrumbs: IBreadcrumb[] = useMemo(
    () => [
      {
        text: 'Back to All Users',
        url: `/admin?${getSearchParams({
          client: clientId,
          model: modelId,
          filter: filter,
        })}`,
      },
    ],
    []
  );

  const apiContext: APICache | undefined = useContext(APICacheContext);

  const DeleteUser = async () => {
    setState({ isDeleting: true, error: undefined });

    try {
      await API.remove(`/model_memberships/${membershipId}`);

      await apiContext?.load([`/models/${modelId}/model_memberships`], true);

      setState({ error: undefined, isDeleting: false, deleteModalFlag: false });
      navigate(
        `/admin?${getSearchParams({
          client: clientId,
          model: modelId,
          filter: filter,
        })}`
      );
    } catch (error: any) {
      setState({ error, isDeleting: false });
      return;
    }
  };

  const getGroupCellRenderer = (modelInstanceId: number) => {
    const onIndividualCheckboxChange = (api: any, data: any) => {
      var objToUpdate: any = state.newApiResponse.GroupPermission.find(
        (obj: any) => {
          return obj.GroupID === data.GroupID;
        }
      );
      let indexOfModelInstance = objToUpdate.ModelInstances.findIndex(
        (e: any) => e.ModelInstanceID === modelInstanceId
      );
      objToUpdate.ModelInstances[indexOfModelInstance].HasGroupAccess =
        objToUpdate.ModelInstances[indexOfModelInstance].HasGroupAccess
          ? false
          : true;
      api.redrawRows();
      // setEnableSave(true);
    };

    return (params: any) => {
      let data = params.data.ModelInstances.find((e: any) => {
        return e.ModelInstanceID === modelInstanceId;
      });
      if (!data) return <>-</>;
      return (
        <Typography
          sx={{ color: '#8c8c8c', alignItems: 'center', display: 'flex' }}
        >
          {data.HasGroupAccess === null ? (
            <Box sx={{ pt: 1, pl: 2.2 }}>-</Box>
          ) : (
            <Checkbox
              onChange={() => {
                onIndividualCheckboxChange(params.api, params.data);
              }}
              disabled={
                state.newApiResponse.GroupRestrictionLevel ===
                  GroupRestrictionLevel.FullAccess ||
                state.newApiResponse.GroupRestrictionLevel !==
                  GroupRestrictionLevel.ModelInstanceLevelAccess ||
                data.HasGroupAccess === null
              }
              checked={data.HasGroupAccess ? true : false}
            />
          )}
        </Typography>
      );
    };
  };

  const useRowSelectionCheckbox = (props: any) => {
    const { data, value } = props;
    const [isChecked, setIsChecked] = useState(false);

    var obj = state.newApiResponse.GroupPermission.find((obj: any) => {
      return obj.GroupID === data.GroupID;
    });

    const onRowCheckboxChange = (data: any) => {
      setIsChecked(!isChecked);
      var objToUpdate: any = state.newApiResponse.GroupPermission.find(
        (obj: any) => {
          return obj.GroupID === data.GroupID;
        }
      );
      objToUpdate.HasGroupAccessToAllModelInstances = !objToUpdate.HasGroupAccessToAllModelInstances;
      gridRef.current?.api.redrawRows();
      // setEnableSave(true);
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          onChange={() => onRowCheckboxChange(data)}
          disabled={
            state.newApiResponse.GroupRestrictionLevel ===
              GroupRestrictionLevel.FullAccess ||
            state.newApiResponse.GroupRestrictionLevel !==
              GroupRestrictionLevel.ModelLevelAccess
          }
          checked={
            obj &&
            obj.HasGroupAccessToAllModelInstances &&
            obj.HasGroupAccessToAllModelInstances === true
              ? true
              : false
          }
        />
        <OverflowTip value={value}></OverflowTip>
      </Box>
    );
  };

  const CustomHeader = (props: any) => {
    const { displayName, api } = props;

    const refreshHeaders = useCallback(() => {
      const columnDefs: ColDef[] = userPermissionsColumns;
      gridRef.current!.api.setGridOption('columnDefs', columnDefs);
    }, []);

    const onHeaderCheckboxChange = () => {
      if (state.checkedHeaderList.includes(displayName)) {
        state.newApiResponse?.GroupPermission?.forEach((group: any) => {
          group.ModelInstances.map((obj: any) => {
            if (obj.ModelInstanceName === displayName) {
              obj.HasGroupAccess = obj.HasGroupAccess === null ? null : false;
            }
            return obj;
          });;
        });
        api.redrawRows();
      } else {
        state.newApiResponse?.GroupPermission?.forEach((group: any) => {
          group.ModelInstances.map((obj: any) => {
            if (obj.ModelInstanceName === displayName) {
              obj.HasGroupAccess = obj.HasGroupAccess === null ? null : true;
            }
            return obj;
          });
        });
        api.redrawRows();
      }

      if (state.checkedHeaderList.includes(displayName)) {
        const index = state.checkedHeaderList.indexOf(displayName);
        if (index > -1) {
          console.log('index', index)
            state.checkedHeaderList.splice(index, 1);
          refreshHeaders()
          // setEnableSave(true);
        }
      } else if (!state.checkedHeaderList.includes(displayName)) {
        state.checkedHeaderList = state.checkedHeaderList.concat(displayName);
        refreshHeaders();
        // setEnableSave(true);
      }
    };

    return (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Checkbox
          onChange={onHeaderCheckboxChange}
          checked={state.checkedHeaderList.includes(displayName) ? true : false}
          disabled={
            state.newApiResponse.GroupRestrictionLevel ===
              GroupRestrictionLevel.FullAccess ||
            state.newApiResponse.GroupRestrictionLevel ===
              GroupRestrictionLevel.ModelLevelAccess
          }
        />
        <Tooltip arrow title={displayName} disableHoverListener={false}>
          <Typography
            variant="subtitle2"
            sx={{
              textWrap: 'auto',
              WebkitLineClamp: 3,
              textOverflow: 'ellipsis',
              maxHeight: 'calc(1.57em*3)',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {displayName}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const getColumns = (apiResponse: any) => {
    const columns: ColDef[] = [
      {
        headerName: 'Group Name',
        field: 'GroupName',
        sortable: true,
        flex: 1,
        minWidth: 200,
        pinned: 'left',
        cellRenderer: useRowSelectionCheckbox,
      },
    ];

    apiResponse &&
      apiResponse[0]?.ModelInstances?.map((obj: any, index: number) => {
        return columns.push({
          headerName: obj.ModelInstanceName,
          field: `ModelInstances${[index]}.HasGroupAccess`,
          minWidth: 300,
          sortable: false,
          flex: 1,
          headerComponent: CustomHeader,
          cellRenderer: getGroupCellRenderer(obj.ModelInstanceID),
        });
      });
    return columns;
  };

  const userPermissionsColumns = getColumns(
    state.newApiResponse?.GroupPermission
  );


  const handleChangeClient = (id: number) => {
    if (!id) return;
    navigate(
      `/admin?${getSearchParams({
        client: id.toString(),
        model: undefined,
        filter: filter,
      })}`
    );
  };

  const handleChangeModel = (id: number) => {
    if (!id) return;
    navigate(
      `/admin?${getSearchParams({
        client: clientId,
        model: id.toString(),
        filter: filter,
      })}`
    );
  };

  const saveChanges = async () => {
    try {
      setState({ savingData: true, error: undefined });
      await API.create(
        `/models/${params().modelId}/users/${userId}/group_memberships`,
        {
          ...state.newApiResponse,
        }
      );
      getData();
      setState({
        savingData: false,
        checkedHeaderList: [],
      });
      // setEnableSave(false);
    } catch (error: any) {
      // setEnableSave(true);
      setState({ savingData: false, error });
      enqueueSnackbar(error.body.message, { variant: 'error' });
    }
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item sm={3}>
            <SelectClient
              clientId={clientId}
              onChange={handleChangeClient}
              size="small"
            />
          </Grid>
          <Grid item sm={3}>
            <SelectModel
              filter="admin"
              clientId={clientId}
              modelId={modelId}
              onChange={handleChangeModel}
              size="small"
            />
          </Grid>
          <br />
        </Grid>
        <Box sx={{ py: 2 }}>
          <Breadcrumbs crumbs={breadcrumbs} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
          <Box sx={{ fontSize: '24px', fontWeight: 500 }}>
            Edit User Permission
          </Box>
          <Box sx={{ fontSize: '24px', fontWeight: 500 }}>
            {membershipId && (
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 2 }}
                onClick={() => setState({ deleteModalFlag: true })}
              >
                Delete User
              </Button>
            )}
            <Button
              variant="outlined"
              size="small"
              sx={{ ml: 2 }}
              onClick={() =>
                navigate(
                  `/admin?${getSearchParams({
                    client: clientId,
                    model: modelId,
                    filter: filter,
                  })}`
                )
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ ml: 2 }}
              onClick={saveChanges}
              disabled={
                state.savingData || state.loadingData || state.isDeleting
                // || !enableSave
              }
            >
              Save
            </Button>
          </Box>
        </Box>

        {!state.loadingData && !isEmpty(state.newApiResponse) ? (
          <>
            <Card sx={{ mb: 2 }}>
              <CardContent sx={{ p: '0.75rem !important' }}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid item xs={2}>
                    <Typography flex="auto" mr={2}>
                      <Typography>
                        <strong>User Name</strong>
                      </Typography>
                      <Typography
                        sx={{ color: '#8c8c8c', wordBreak: 'break-all' }}
                      >
                        <OverflowTip
                          value={state.newApiResponse?.UserName}
                          variant={'body1'}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography flex="auto" mr={2}>
                      <Typography>
                        <strong>User Email</strong>
                      </Typography>
                      <Typography
                        sx={{ color: '#8c8c8c', wordBreak: 'break-all' }}
                      >
                        <OverflowTip
                          value={state.newApiResponse?.UserEmail}
                          variant={'body1'}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <Switch
                      checked={
                        state.newApiResponse.GroupRestrictionLevel ===
                          GroupRestrictionLevel.ModelLevelAccess ||
                        state.newApiResponse.GroupRestrictionLevel ===
                          GroupRestrictionLevel.ModelInstanceLevelAccess
                      }
                      className={UtilClasses.NO_MARGINS}
                      onChange={() => {
                        setState({
                          newApiResponse: {
                            ...state.newApiResponse,
                            GroupRestrictionLevel:
                              state.newApiResponse.GroupRestrictionLevel ===
                              GroupRestrictionLevel.FullAccess
                                ? GroupRestrictionLevel.ModelLevelAccess
                                : GroupRestrictionLevel.FullAccess,
                          },
                        });
                        // setEnableSave(true);
                      }}
                    />
                    <strong>Group Restrictions </strong>
                    <InformationIcon
                      tooltipContent={
                        'When Group Restrictions is turn off, user will have  full access.'
                      }
                      placement={'bottom'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography flex="auto" mr={2}>
                      <Typography>
                        <strong>Restriction Level</strong>
                      </Typography>
                      <Typography
                        sx={{ color: '#8c8c8c', wordBreak: 'break-all' }}
                      >
                        <RadioGroup
                          row
                          onChange={() => setState({ confirmationFlag: true })}
                          value={
                            state.newApiResponse.GroupRestrictionLevel ===
                            GroupRestrictionLevel.ModelLevelAccess
                              ? 'Model'
                              : state.newApiResponse.GroupRestrictionLevel ===
                                  GroupRestrictionLevel.ModelInstanceLevelAccess
                                ? 'ModelInstance'
                                : null
                          }
                        >
                          <FormControlLabel
                            value="Model"
                            control={
                              <Radio
                                disabled={
                                  state.newApiResponse.GroupRestrictionLevel ===
                                  GroupRestrictionLevel.FullAccess
                                }
                              />
                            }
                            label="Model"
                          />
                          <FormControlLabel
                            value="ModelInstance"
                            control={
                              <Radio
                                disabled={
                                  state.newApiResponse.GroupRestrictionLevel ===
                                  GroupRestrictionLevel.FullAccess
                                }
                              />
                            }
                            label="Model Instance"
                          />
                        </RadioGroup>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                {/* {state.error && (
                  <Box mt={2}>
                    <Snackbar
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={Boolean(state.error)}
                      title="Something went wrong"
                    >
                      <Alert
                        onClose={() => setState({ error: undefined })}
                        severity="error"
                      >
                        <AlertTitle>Something went wrong</AlertTitle>
                        Check your connection and try again
                      </Alert>
                    </Snackbar>
                  </Box>
                )} */}
              </CardContent>
            </Card>

            <Grid
              container
              spacing={0}
              sx={{ paddingBottom: 10, display: 'flex' }}
            >
              <Backdrop
                sx={{
                  color: 'white',
                  zIndex: (theme) => theme.zIndex.drawer + 3,
                  backgroundColor: alpha('#596B7A', 0.7),
                }}
                open={state.savingData}
              >
                <Box>
                  <CenteredSpinner /> <br></br>
                  <Typography variant="h6"> Saving Changes</Typography>
                </Box>
              </Backdrop>
              <Table
                id="editGroupPermissions"
                rowSelection="multiple"
                isLoading={false}
                isSuccess
                gridRef={gridRef}
                onColumnHeaderClicked={(e) => {}}
                onRowSelected={(e) => {}}
                pagination={false}
                rowCount={userPermissionsRows.length}
                rowData={userPermissionsRows}
                // rowCount={state?.newApiResponse?.GroupPermission.length}
                // rowData={state?.newApiResponse?.GroupPermission}
                columnDefs={userPermissionsColumns}
                defaultColDef={{
                  suppressAutoSize: false,
                  suppressMenu: true,
                }}
                noRowsOverlayComponent={() =>
                  !isEmpty(state?.newApiResponse) &&
                  state?.newApiResponse.GroupPermission.length <= 0 ? (
                    'No groups found for this model.'
                  ) : (
                    <>
                      <CenteredSpinner />
                    </>
                  )
                }
                // gridOptions={{
                //   defaultColDef: {
                //     // resizable: true,
                //     // sortable: true,
                //   },
                // }}
              />
            </Grid>
          </>
        ) : (
          <Box>
            {state.loadingData && <CenteredSpinner />}
            {!state.loadingData && state.error && (
              <Box mt={2} mb={3}>
                <Alert
                  icon={false}
                  severity="error"
                  title="Something went wrong"
                >
                  <AlertTitle>Something went wrong</AlertTitle>
                  Failed to fetch group permissions for the user, check your
                  connection and try again
                </Alert>
              </Box>
            )}
          </Box>
        )}
        <PopupDialog
          open={state.confirmationFlag}
          close={() => setState({ confirmationFlag: false })}
          title="Confirm Change in Restriction Level"
          maxWidth="md"
          primaryButtons={[
            {
              id: 'resetButton',
              label: 'Confirm',
              onClick: handleRestrictionLevelChange,
            },
          ]}
        >
          Do you want to change restriction level to{' '}
          <strong>
            {state.newApiResponse.GroupRestrictionLevel ===
            GroupRestrictionLevel.ModelLevelAccess
              ? 'Model Instance'
              : 'Model'}
          </strong>
          ?
          <Typography variant="body2" mt={2}>
            {state.newApiResponse.GroupRestrictionLevel ===
            GroupRestrictionLevel.ModelLevelAccess ? (
              <Box>
                This action will reset the group permissions for all groups.
              </Box>
            ) : (
              <Box>
                This action will reset the group permissions for all model
                instances.
              </Box>
            )}
          </Typography>
        </PopupDialog>
        <PopupDialog
          open={state.deleteModalFlag}
          close={() => setState({ deleteModalFlag: false })}
          title="Remove User ?"
          maxWidth="md"
          primaryButtons={[
            {
              id: 'resetButton',
              label: 'Confirm',
              onClick: DeleteUser,
            },
          ]}
          isLoading={state.isDeleting}
        >
          Do you want to remove <b>{state.newApiResponse.UserName}</b> from the
          model?
          <Typography variant="body2" mt={2}>
            Any group permission restrictions for this user will be cleared and
            will have to be added back if user's model access is restored.
          </Typography>
        </PopupDialog>
      </Container>
    </Box>
  );
};

export default EditGroupPermissions;
